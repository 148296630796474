@import '../../theme/variables';
@import '../../theme/snippets';

.section {
  width: 100%;
  margin: 16px 0;

  .sectionTitleWrapper {
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid $secondary-color-03;

    &.withViewAll {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .sectionTitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: $secondary-color-00;
    }

    .sectionViewAll {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $primary-color-00;
    }
  }

  .sectionContent {
    @extend %hidden-scroll;
    width: 100%;
    position: relative;
  }
  .feedActivities {
    margin-bottom: 16px;
  }
}

@media (max-width: $tablets-width) {
  .section {
    margin: 0;

    .feedActivities {
      margin-bottom: 0;
    }
  }
}
