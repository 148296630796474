@import './variables';

%button-shape {
  @extend %centred-content;
  flex-direction: row;
  border-radius: 8px;
  cursor: pointer;
}

%error-wrapper {
  padding-left: 16px;

  p {
    color: $error-bride;
    left: 16px;
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
}

%sign-container {
  position: relative;
  width: 100%;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
}

%header-left-link {
  color: $secondary-color-00;
  float: right;

  p {
    margin: 0 5px;
    display: inline;
  }

  a {
    color: $secondary-color-00;
    font-style: normal;
    font-weight: 450;
    text-decoration-line: underline;

    &:hover {
      text-decoration: none;
      color: $primary-color-00;
    }
  }
}

%centred-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

%delete-icon {
  position: absolute !important;
  background-color: $white-transparent;
  border-radius: 4px;
  right: 8px;
  bottom: 8px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  z-index: 9;
}

%hidden-scroll {
  overflow: scroll;
  &::-webkit-scrollbar {
    /* Chrome */
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

%ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%grid_1_1_2 {
  display: grid;
  grid-gap: 0 1rem;
  grid-template-columns: 1fr 1fr 2fr;
}

%inside-item {
  width: max-content;
  max-width: 100%;
  font-size: 14px;
  padding: 6px 8px 6px 8px;
  border-radius: 4px;
  margin-left: 8px;
  height: 32px;
  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: revert;

  &.grey {
    background-color: $neutral-gray;
    &:hover {
      background-color: $secondary-color-03;
    }
    &.disabled {
      background-color: $secondary-color-02;
    }
  }

  &.white {
    border: 1px solid $secondary-color-03;
    &:hover {
      border: 1px solid $secondary-color-02;
    }
    &.disabled {
      background-color: $secondary-color-02;
    }
  }

  &.blue {
    background-color: $primary-color-00;
    color: #ffffff;
    p {
      color: #ffffff;
    }
    &:hover {
      background-color: $primary-color-01;
    }
    &.disabled {
      background-color: $secondary-color-02;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .image {
    display: flex;
  }

  p {
    @extend %toLongTextHandling;
    max-width: 250px !important;
    margin: 0;
  }
}

%grid_1_1_1 {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

%itemIndex {
  @extend %centred-content;
  height: 40px;
  width: 40px;
  background-color: $primary-color-00;
  color: $neutral-white;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 8px;
  margin-bottom: 16px;
}

%lightBorderedText {
  padding: 6px 8px;
  border: 1px solid $secondary-color-03;
  border-radius: 4px;
}

%checkboxContainerCommonStyles {
  display: flex;
  align-items: center;
  position: relative;

  p {
    margin-left: 5px;
  }

  input {
    appearance: none;
    height: 24px;
    width: 24px;
    min-width: 24px;
    min-height: 24px;
    outline: none;
    content: none;
    border: 1px solid $secondary-color-03;
    border-radius: 4px;
    cursor: pointer;

    &:checked {
      border: unset;
      background: url('../../public/icons/checkbox/Checkbox.svg');
      background-size: contain;
    }

    &.disabled {
      background-color: $secondary-color-03;
    }
  }

  .label {
    color: $secondary-color-00;
    margin-left: 4px;
  }
  .labelRegular {
    margin-left: 4px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
  }
}

%inlineButton {
  width: fit-content;
  padding: 4px 12px;
  color: $primary-color-00;
  background-color: $brand-background;
  border: 1px solid $brand-background;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  justify-self: flex-end;
  cursor: pointer;

  &:hover {
    border: 1px solid $primary-color-00;
  }
}

%customerPageContainer {
  padding: 40px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 55vh;
}

%customerPageContent {
  width: 100%;
  max-width: 1116px;

  h1 {
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }
}

%toLongTextHandling {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    overflow: auto;
    overflow-y: hidden;
    padding-bottom: 0.5rem;
  }
}

%tableToolBlock {
  .toolBlock {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1.5rem;
    margin-bottom: 24px;
  }

  .toolBlockLeftSide {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 0 1rem;

    button {
      margin-top: unset !important;
    }
  }

  .toolBlockRightSide {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 0 1rem;

    button {
      margin-top: unset !important;
    }
  }
}

%customizedScrollByDiv {
  div {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  div:hover {
    -ms-overflow-style: unset; /* IE and Edge */
    scrollbar-width: unset; /* Firefox */
  }

  *::-webkit-scrollbar {
    width: 12px !important;
    height: 12px !important;
  }

  /* Chrome */
  div::-webkit-scrollbar-track {
    background-color: red;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-radius: 5px;
  }
  div::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
    border: 3px solid transparent;
    background-clip: content-box;
  }
}

%styledScroll {
  div::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  div::-webkit-scrollbar-track {
    background-color: red;
    border-right: 1px solid red;
    border-bottom: 1px solid #cdcdcd;
    border-left: 1px solid #dedede;
    border-top: 1px solid #dedede;
    border-radius: 5px;
  }

  div::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
    border: 3px solid transparent;
    background-clip: content-box;
  }
}

%wysiwygSpecialStyles {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  li::before {
    color: $secondary-color-00;
  }

  p {
    a {
      color: $secondary-color-01;
    }

    a:hover {
      color: $primary-color-00;
    }
  }

  a {
    cursor: pointer;
    color: $secondary-color-01;
  }

  a:hover {
    color: $primary-color-00;
  }

  blockquote {
    display: block;
    font-family: inherit;
    font-size: inherit;
    color: #999;
    margin-inline-end: 0;
    margin-inline-start: 0;
    padding: 0 5px 0 20px;
    border: solid #b1b1b1;
    border-width: 0 0 0 5px;

    em {
      box-sizing: border-box;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }
  }

  table {
    display: table;
    table-layout: auto !important;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;

    tr {
      border: 1px solid #efefef;

      td {
        border: 1px solid #e1e1e1;
        padding: .4em;
        background-clip: padding-box;
      }
    }
  }
}
