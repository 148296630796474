@import '../../theme/variables';

.searchResultFor {
  width: 100%;
  padding: 24px 48px;

  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $secondary-color-02;
  }

  .query {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    color: $secondary-color-00;
  }
}

.gearActivities {
  margin-bottom: 0 !important;
  padding: 0 1rem;
}

.container {
  width: 100%;
  display: flex;
  gap: 24px;
  padding: 48px 31px;
  background-color: #fff;
}

.promoBox {
  margin: 16px 16px 0 16px;
}

.promo {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.forMob {
  display: none;
}


.listWrapper {
  width: calc(100% - 318px - 24px);

  .listHeader {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 16px;
    border-bottom: 1px solid $secondary-color-03;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .listHeaderResults {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $secondary-color-00;
    }

    .listHeaderControls {
      display: flex;
      white-space: nowrap;
      height: 42px;

      .listHeaderViewMode {
        display: flex;
        border: 1px solid $secondary-color-03;
        border-radius: 8px;
        overflow: hidden;
        margin-left: 16px;

        .listHeaderViewModeItem {
          display: flex;
          align-items: center;
          width: 120px;
          padding: 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: $secondary-color-02;
          background-color: #fff;
          cursor: pointer;

          .listHeaderViewModeItemIcon {
            margin-right: 8px;
          }

          &.activeMode {
            background-color: $secondary-color-03;
            color: $secondary-color-00;
            cursor: default;
          }
        }
      }
    }
  }

  .tags {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    gap: 8px;
    overflow-x: auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .tagsItem {
      white-space: nowrap;
      padding: 6px 8px;
      background-color: $neutral-gray;
      color: $secondary-color-00;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      border-radius: 4px;

      &.activeTag {
        background-color: $secondary-color-03;
      }
    }
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}

.listSort {
  display: flex;
  align-items: center;

  .listSortLabel {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $secondary-color-02;
    margin-right: 8px;
  }

  .listSortDropdown {
    position: relative;
    width: 115px;
    padding-left: 8px;

    .listSortDropdownValue {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $secondary-color-00;
      cursor: pointer;

      .listSortDropdownValueIcon {
        width: 24px;
        height: 24px;
        margin-left: 8px;
      }
    }

    .listSortDropdownOptions {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      width: 100%;
      background-color: #fff;
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .listSortDropdownOptionsItem {
        font-size: 1rem;
        cursor: pointer;
        color: $secondary-color-00;
        &:hover {
          color: $primary-color-01;
        }
      }
    }
  }
}

@media (max-width: $desktop-width) {
  .container {
    flex-direction: column;
  }

  .listWrapper {
    width: 100%;
  }
}

@media (max-width: $tablets-width) {
  .container {
    padding: 8px 16px;
    gap: 16px;
  }

  .listWrapper {
    .list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    .listHeader {
      .listHeaderControls {
        .listHeaderViewMode {
          .listHeaderViewModeItem {
            width: unset;
            padding: 4px;

            .listHeaderViewModeItemIcon {
              margin-right: 0;
            }

            .listHeaderViewModeItemTitle {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $under-tablet-width) {
  .gearActivities {
    padding: 0;
  }

  .forNotMob {
    display: none;
  }

  .forMob {
    display: flex;
  }

  .sellTypeContainer {
    display: flex;
    justify-content: space-around;
    position: relative;

    .sellTypeCell {
      width: 40%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-weight: 400;
        font-size: 16px;
      }

      .submenuItem {
        margin: 0 40px 0 24px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
  .separator {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100vw;
    height: 2px;
    background-color: $secondary-color-03;
    z-index: -1;
  }
  .active {
    border-bottom: 2px solid $primary-color-00;
  }


  .searchResultFor {
    padding: 24px 16px;
  }
}
