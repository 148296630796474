@import '../../theme/variables';

$mapHeight: 583px;
$mapHeightTablet: 591px;
$mapHeightMobile: 355px;

.container {
  width: 100%;
  height: $mapHeight;
  position: relative;
}

.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.mapContainer {
  width: 100%;
  height: 100%;
}

.gearMarker {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 40px;
  padding: 4px 8px;
  color: $secondary-color-01;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;

  &.gearMarkerActive {
    color: #fff;
    background-color: $primary-color-00;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}

.bucketMarker {
  background-color: $secondary-color-01;
  border-radius: 40px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding: 4px 8px;
  cursor: pointer;
}

.gearInfoWindow {
  background-color: #fff;
  width: 320px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  .gearInfoWindowImage {
    position: relative;
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .gearInfoWindowClose {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba($secondary-color-01, 30%);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .gearInfoWindowContent {
    padding: 16px 8px;
    color: $secondary-color-00;
    font-size: 18px;
    line-height: 24px;

    .gearInfoWindowTitleRow {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .gearInfoWindowTitle {
        flex: 1;
      }

      .gearInfoWindowFavorites {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;

        img {
          width: 24px;
          height: 24px;
          margin-left: 4px;
        }
      }
    }

    .gearInfoWindowPriceRow {
      .gearInfoWindowPriceUnit {
        color: $secondary-color-02;
      }
    }
  }
}

.geoHashList {
  position: absolute;
  top: 0;
  right: 8px;
  width: 400px;
  max-height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .geoHashListItem {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin: 8px 0;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    color: $secondary-color-00;

    .itemImage {
      width: 64px;
      height: 64px;
      border-radius: 8px;
      overflow: hidden;
      flex: none;
    }

    .itemContent {
      flex: 1;
      overflow: hidden;

      .itemTitle {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 4px;
      }

      .itemPriceConteiner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        .itemPriceWrapper {
          display: flex;
          align-items: flex-end;
          overflow: hidden;

          .itemPrice {
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;
            margin-right: 8px;
            flex: none;
          }

          .itemSize {
            font-size: 16px;
            line-height: 24px;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

@media (max-width: $desktop-width) {
  .container {
    height: $mapHeightTablet;
  }
}

@media (max-width: $tablets-width) {
  .container {
    height: $mapHeightMobile;
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .gearInfoWindow {
    width: 260px;
    .gearInfoWindowImage {
      height: 140px;
    }

    .gearInfoWindowClose {
      top: 4px;
      right: 4px;
      width: 26px;
      height: 26px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .gearInfoWindowContent {
      padding: 8px 4px;
    }
  }

  .geoHashList {
    top: unset;
    right: unset;
    left: 0;
    bottom: 8px;
    width: 100%;
    height: unset;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;

    .geoHashListItem {
      width: 300px;
      margin: 0 8px;
    }
  }
}

.myLocationButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.myLocationButton:hover {
  background-color: #f0f0f0;
}
